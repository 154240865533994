<template>
  <a-card>
    <a-form-model ref="searchFrom" :model="search_form" layout="inline">
      <a-form-model-item label="提示语">
        <a-input placeholder="请输入" v-model.trim="search_form.content" :maxLength="100" style="width: 250px" />
      </a-form-model-item>
      <a-form-model-item label="问题类型">
        <a-select v-model="search_form.question_type" style="width: 250px" placeholder="请选择" allow-clear>
          <a-select-option key="">全部</a-select-option>
          <a-select-option v-for="item in question_type_list" :key="item.code">
            {{ item.value }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item>
        <div class="mtp">
          <a-button class="ml-10p" :icon="'redo'" @click="resetSearch" type="dashed"> 重置 </a-button>
          <a-button class="ml-10p" :type="'primary'" :icon="'search'" @click="searchClick"> 查询 </a-button>
          <a-button class="ml-10p" :type="'primary'" :icon="'plus'" @click="handleActionClick('add', {})">
            新建提示语
          </a-button>
        </div>
      </a-form-model-item>
    </a-form-model>

    <div class="data-table">
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :row-key="(record, index) => index"
        :pagination="false"
      >
        <div slot="type" slot-scope="text, record">
          <span>{{ record.type == 'START' ? '开始语' : record.type == 'END' ? '结束语' : '-' }}</span>
        </div>
        <div slot="content" slot-scope="text, record">
          <a-tooltip>
            <template slot="title">
              {{ record.content }}
            </template>
            <span class="content-text">{{ record.content || '-' }}</span>
          </a-tooltip>
        </div>
        <div slot="question_type" slot-scope="text, record">
          <span>{{ getQuestionTypeName(record.question_type) }}</span>
        </div>
        <div slot="action" slot-scope="text, record">
          <a @click="handleActionClick('del', record)">删除</a>
          <a-divider type="vertical" />
          <a @click="handleActionClick('edit', record)">编辑</a>
        </div>
      </a-table>
      <base-pagination
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </div>

    <!--新建编辑-->
    <a-modal
      :visible="messagePromptModal"
      :title="title"
      :mask-closable="false"
      @cancel="handleCancel"
      @ok="handleOk"
      width="50%"
    >
      <a-form-model class="form" ref="form" :model="form" :rules="rules" layout="inline">
        <a-row>
          <a-col>
            <a-form-model-item label="类型" prop="type">
              <a-select v-model="form.type" style="width: 300px" placeholder="请选择" allow-clear>
                <a-select-option :value="el.value" v-for="el in typeList" :key="el.value">
                  {{ el.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row style="padding: 20px 0">
          <a-col>
            <a-form-model-item label="提示语" prop="content">
              <a-textarea
                placeholder="请输入"
                v-model.trim="form.content"
                :maxLength="100"
                style="width: 400px"
                :rows="4"
                @pressEnter="handleEnter"
              />
              <span class="tips_count">{{ form.content ? form.content.length : 0 }}/100</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="form.type != 'END'">
          <a-col>
            <a-form-model-item label="问题类型" prop="question_type">
              <a-select v-model="form.question_type" style="width: 300px" placeholder="请选择" allow-clear>
                <a-select-option v-for="item in question_type_list" :key="item.code">
                  {{ item.value }}
                </a-select-option>
              </a-select>
              <p style="line-height: 1; font-size: 12px; color: #8b8e92">
                为方便跟踪统计，一个提示语仅能关联一种问题类型
              </p>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import _https from '@/api/SCRM';
import _ from 'lodash';

export default {
  name: 'messagePromptList',
  data() {
    return {
      search_form: {
        content: '',
        question_type: '',
      },
      question_type_list: [
        {
          code: '1',
          value: '自然流量-旧',
        },
        {
          code: '2',
          value: '操作-旧',
        },
        {
          code: '3',
          value: '投放-旧',
        },
        {
          code: '4',
          value: '数据-旧',
        },
        {
          code: '5',
          value: '处理-旧',
        },
        {
          code: '6',
          value: '风火轮-旧',
        },
        {
          code: '7',
          value: '组件-旧',
        },
        {
          code: '8',
          value: '拍摄剪辑-旧',
        },
        {
          code: '9',
          value: '线索-旧',
        },
        {
          code: '10',
          value: '平台规则-旧',
        },
        {
          code: '0',
          value: '其他-旧',
        },
        { code: '11', value: '功能相关' },
        { code: '12', value: '内容相关' },
        { code: '13', value: '使用相关' },
        { code: '14', value: '账号相关' },
        { code: '15', value: '营销任务' },
        { code: '16', value: '短视频投流' },
        { code: '17', value: '直播投流' },
        { code: '18', value: '短视频分析' },
        { code: '19', value: '账号分析' },
        { code: '20', value: '直播分析' },
        { code: '21', value: '平台规则' },
        { code: '22', value: '直播相关' },
        { code: '23', value: '组件' },
        { code: '24', value: '培训相关' },
      ],
      messagePromptModal: false,
      loading: false,
      columns: [
        {
          title: '类型',
          dataIndex: 'type',
          width: 120,
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '提示语',
          dataIndex: 'content',
          scopedSlots: { customRender: 'content' },
          width: 300,
        },
        {
          title: '问题类型',
          dataIndex: '',
          scopedSlots: { customRender: 'question_type' },
          width: 140,
        },
        {
          title: '更新时间',
          dataIndex: 'mtime',
          key: '',
          width: 220,
        },
        {
          title: '操作',
          key: 'action',
          width: 160,
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      title: '新建提示语',
      form: {
        type: undefined,
        content: '',
        question_type: undefined,
      },
      rules: {
        type: [{ required: true, message: '请选择类型', trigger: 'change' }],
        content: [{ required: true, message: '请输入提示语', trigger: 'blur' }],
        question_type: [{ required: true, message: '请选择问题类型', trigger: 'change' }],
      },
      typeList: [
        {
          value: 'START',
          name: '开始语',
        },
        {
          value: 'END',
          name: '结束语',
        },
      ],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      let params = this.search_form;
      params.page = this.pagination.current;
      params.size = this.pagination.pageSize;
      _https.getList(params).then((res) => {
        if (res.code == 200) {
          this.data = res.data.list || [];
          this.pagination.total = res.data.total || 0;
        } else {
          this.data = [];
          this.pagination.total = 0;
          this.$message.error(res.message);
        }
      });
    },
    resetSearch() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.search_form = {
        content: '',
        question_type: '',
      };
      this.getList();
    },
    searchClick() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.getList();
    },
    handleActionClick(type, record) {
      let that = this;
      switch (type) {
        case 'add':
          this.messagePromptModal = true;
          this.title = '新建提示语';
          break;
        case 'del':
          that.$confirm({
            title: '确定删除该提示语？',
            okText: '确定',
            cancelText: '取消',
            onOk() {
              console.log('ok');
              _https.delHint(record.id).then((res) => {
                if (res.code == 200) {
                  that.$message.success('删除成功');
                } else {
                  that.$message.error(res.message);
                }
                that.getList();
              });
            },
            onCancel() {},
          });
          break;
        case 'edit':
          this.messagePromptModal = true;
          this.form = _.cloneDeep(record || {});
          this.title = '编辑提示语';
          break;
      }
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getList();
    },
    handleCancel() {
      this.$refs.form.resetFields();
      this.form = {
        type: undefined,
        content: '',
        question_type: undefined,
      };
      this.messagePromptModal = false;
      this.getList();
    },
    handleOk() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          (this.form.id ? _https.updateHint(this.form) : _https.saveHint(this.form)).then((res) => {
            if (res.code == 200) {
              this.$message.success('保存成功');
              this.handleCancel();
            } else {
              this.$message.error(res.message || '保存失败');
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    getQuestionTypeName(val) {
      const index = this.question_type_list.findIndex((item) => item.code === val);
      return index > -1 ? this.question_type_list[index].value : '-';
    },
    handleEnter(e) {
      const et = e || window.event;
      const keycode = et.charCode || et.keyCode;
      if (keycode == 13) {
        if (window.event) {
          window.event.returnValue = false;
        } else {
          e.preventDefault();
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.ml-10p {
  margin-left: 10px;
}

.mtp {
  padding-left: 40px;
  display: flex;
  justify-content: flex-end;
}

.data-table {
  padding-top: 30px;
}

.content-text {
  display: inline-block;
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
